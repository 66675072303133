.keypad {
  display: grid;
  gap: 1.5rem 2rem;
  place-items: center;
  grid-template-rows: repeat(4, 1fr) auto;
  grid-template-columns: repeat(3, 1fr);

  &__call-btn {
    .material-symbols-outlined {
      font-size: 2.5rem;
    }

    &:hover {
      color: #5bc236;
    }

    &:active {
      @include btn-active-text-shadow(green);
    }

    @media only screen and (max-width: 600px) {
      .material-symbols-outlined {
        font-size: 1.5rem;
      }

      @include rounded(4rem);
    }
  }

  &__backspace-btn {
    .material-symbols-outlined {
      font-size: 3rem;
    }

    &:hover {
      color: #9baacf;
    }

    &:active {
      transform: scale(0.75);
      @include btn-active-text-shadow(#ff4c00);
    }

    &:disabled {
      display: none;
    }

    @media only screen and (max-width: 600px) {
      .material-symbols-outlined {
        font-size: 2rem;
      }

      @include rounded(4rem);
    }
  }
}
