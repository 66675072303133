/**
 * Create a rounded element with a specified size and border radius.
 * 
 * @param {length} $size - The width and height of the element.
 * @param {length} $radius - The border radius for rounding the element.
 */
@mixin rounded($size: 6.25rem, $radius: 50%) {
  width: $size;
  height: $size;
  border-radius: $radius;
}

// engrave color effect
@mixin engrave {
  background: #b1b1b1;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  text-shadow: rgba(255, 255, 255, 0.5) 0.0625rem 0.125rem 0.0625rem;
}

// button text shadow
@mixin btn-active-text-shadow($color) {
  color: $color;
  text-shadow:
    0 0 0.0625rem $color,
    0 0 0.3rem $color;
}
