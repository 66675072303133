// button style
button {
  user-select: none;
  background-color: #e0e5ec;
  box-shadow:
    inset 0.2rem 0.2rem 0.5rem #fff,
    inset -0.2rem -0.2rem 0.5rem rgba(195, 193, 198, 0.9),
    0.3rem 0.3rem 0.5rem rgba(195, 193, 198, 0.9),
    -0.2rem -0.2rem 0.4rem #fff;
  transition: all 0.3s;

  @include engrave;
  @include rounded;

  &:active {
    transform: scale(0.75);
    box-shadow:
      inset 0.2rem 0.2rem 0.5rem #c3c1c6,
      inset -0.2rem -0.2rem 0.5rem #ffffff;
  }
}

/**
 *
 * Styles for a dialpad button.
 *
 * The `.dialpad-btn` class provides styling for a button commonly used in dialpad interfaces.
 * It includes properties for cursor, layout, background color, and rounded corners.
 * It also defines styles for the title and subtitle elements within the button.
 *
 */

.dialpad-btn {
  display: flex;
  gap: 0.25rem;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @include rounded;

  &__title {
    @include engrave;
    font-size: 1.8rem;
    font-weight: 900;
    transform: scale(1);
    transition: all 0.3s;
  }

  &__subtitle {
    @include engrave;
    font-size: 1rem;
    font-weight: 700;
    letter-spacing: 0.125rem;
    transform: scale(1);
    transition: all 0.3s;
  }

  &:hover {
    .dialpad-btn__title,
    .dialpad-btn__subtitle {
      color: #f8b639;
    }
  }

  &:active {
    .dialpad-btn__title {
      transform: scale(0.75);
      @include btn-active-text-shadow(#ff4c00);
    }

    .dialpad-btn__subtitle {
      transform: scale(0.95);
      @include btn-active-text-shadow(#ff7025);
    }
  }

  @media only screen and (max-width: 600px) {
    gap: 0.125em;
    @include rounded(4rem);

    &__title {
      font-size: 1.25rem;
    }

    &__subtitle {
      font-size: 0.5rem;
    }
  }
}
