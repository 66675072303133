/**
 *
 * Styles for an input element.
 *
 * The `.input-element` class provides styling for an input element, defining properties
 * for padding, font size, background color, and border radius. These styles help create
 * a visually appealing and user-friendly input field.
 *
 */
.input-element {
  padding: 0.5rem 0.75rem;
  outline: none;
  border: 0;
  font-size: 2rem;
  border-radius: 1rem;
  caret-color: red;
  background-color: gray;
  box-shadow:
    inset 0.2rem 0.2rem 0.5rem rgba(195, 193, 198, 0.9),
    inset -0.2rem -0.2rem 0.5rem #ffffff;

  @include engrave;

  &::selection {
    color: #ff7025;
    background-color: rgba(255, 77, 0, 0.1);
  }

  @media only screen and (max-width: 600px) {
    font-size: 1.3rem;
    border-radius: 0.75rem;
    padding: 0.35rem 0.5rem;
  }
}
